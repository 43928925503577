<template>
  <div>
    <!-- <v-alert
      dark
      dense
      dismissible
      :value="!!alert['message']"
      :type="alert['type'] || type"
      @input="closeAlert"
      class="ma-4 mr-12 ml-12"
    >
      {{ alert["message"] | camelcase }}
    </v-alert> -->

    <v-overlay :value="!!alert['message']">
      <v-btn :color="alert['type'] || type" @click="closeAlert" v-html="alert['message']"></v-btn>
    </v-overlay>
  </div>
</template>

<script>
  import { sync } from 'vuex-pathify';

  export default {
    data() {
      return {
        show: false,
        message: '',
        type: 'error',
        autohide: false,
      };
    },
    computed: {
      alert: sync('misc/alert'),
    },
    methods: {
      closeAlert() {
        this.alert['message'] = '';
      },
    },
    watch: {
      alert: {
        handler(val) {
          const hide = val?.autohide;
          if (hide) {
            setTimeout(() => {
              this.closeAlert();
            }, 2000);
          }
        },
        deep: true,
      },
    },
  };
</script>
<style>
  /* .v-alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    box-shadow: 0 0 200vw 200vh rgb(0 0 0 / 60%) !important;
  } */
</style>
